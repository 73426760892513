<template>
   <v-col
      cols="12"
      class="pa-0"
      :style="{'min-height': modal.height?modal.height:'1px'}"
      >
   </v-col>
</template>

<script>
export default {
   props: [
      'modal', 
      'customStyle',
      ]
}
</script>